import {YoGameEnvironmentRoles} from '../../config/yo-game-environment-roles';
import {YoAuthUser} from '@yakoffice/yakoffice-firebase';
import {DataObjectBase} from '../../model/data';

export enum DataPropertyEditorLevel {
  Administrator,
  CustomerService,
  None
}


export const IsPropertyEditable = (property: DataObjectBase, projectId: string, gameEnvironmentId: string, authUser: YoAuthUser | null): boolean => {

  // Todo: the additional EditorLevel permissions were added before the required PPA updates were deployed in production. This caused all editable fields in production to be false because editorLevel property did not exist on the data object. This checks for that and falls back solely on the editor level of the user so that we can continue to edit player fields until the PPA update is deployed.

  const authUserRoles = authUser?.getGameEnvironmentRoles(projectId, gameEnvironmentId);

  if(property.editorLevel == undefined) {
    if(authUserRoles == undefined)
      return false;

    return !!authUserRoles.find(role => role == YoGameEnvironmentRoles.EditPlayers) && property.isEditable;
  }

  const authUserCanEditProperty = DetermineDataPropertyEditorLevelsFromGameEnvironmentRoles(authUserRoles) <= property.editorLevel;

  return authUserCanEditProperty && property.isEditable;
}

const DetermineDataPropertyEditorLevelsFromGameEnvironmentRoles = (authUserRoles: string[] | undefined): DataPropertyEditorLevel => {

  if(authUserRoles == undefined)
    return DataPropertyEditorLevel.None;

  if(authUserRoles.find(role => role == YoGameEnvironmentRoles.EditPlayers))
    return DataPropertyEditorLevel.Administrator;

  else if(authUserRoles.find(role => role == YoGameEnvironmentRoles.ServicePlayers))
    return DataPropertyEditorLevel.CustomerService;

  else
    return DataPropertyEditorLevel.None;
}
