import React, {FC} from 'react';
import {Routes, Route, Navigate, useParams} from "react-router-dom";
import {CreateDataSourceRoute, PlayersRouteItems} from '../../PlayersRoutes';
import {useDataSources} from '../../../components';
import DataSourceIndex from './data-source';
import {YoGameEnvironmentRoles} from '../../../config/yo-game-environment-roles';
import {useAuthUser} from '@yakoffice/yakoffice-firebase';
import {matchDeeply, NoAccess} from '@yakoffice/shared-components';
import {YakofficeHeader} from "@yakoffice/yakoffice-header";

export const GameEnvironmentIndex: FC = () => {

    const {projectId, gameEnvironmentId} = useParams() as { projectId: string, gameEnvironmentId: string };
    const dataSources = useDataSources();
    const authUser = useAuthUser();

    const hasViewGameEnvironmentClaim = authUser?.hasGameEnvironmentRoleClaim(projectId, gameEnvironmentId, YoGameEnvironmentRoles.ViewPlayers)

    return <YakofficeHeader hostedInPortal={false}>
        {hasViewGameEnvironmentClaim
            ? <Routes>
                <Route path={""} element={dataSources.length > 0 && <Navigate
                    to={CreateDataSourceRoute(projectId, gameEnvironmentId, dataSources.find(_ => true)?.id)}/>}/>
                <Route path={matchDeeply(PlayersRouteItems.DataSourceIdParam)} element={<DataSourceIndex/>}/>
            </Routes>
            : <NoAccess/>}
    </YakofficeHeader>
}

export default GameEnvironmentIndex;

