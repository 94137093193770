import Button from 'react-bootstrap/Button';
import React from 'react';
import {useShowModal} from '@yakoffice/custom-modal';
import {useNavigate} from 'react-router-dom';
import {CustomSpinner} from '@yakoffice/custom-spinner';


export interface IClearPlayerCacheModalModal {
  clearPlayerCacheModal: (modalAction: () => Promise<boolean>, modalBody: string, useValidation: boolean, userId: string) => void;
}


export const useClearPlayerCacheModal = (): IClearPlayerCacheModalModal => {

  const showModal = useShowModal();
  const navigate = useNavigate()


  const closeModalAndRedirect = () => {
    showModal({show: false, title: "", body: "", canClose: false});
    navigate(0);
  }

  const clearPlayerCacheModal = async (modalAction: () => Promise<boolean>, modalBody: string, useValidation: boolean, userId: string) => {

    const doAction = async () => {

      showModal({
                  show: true,
                  title: `Clear Player Cache`,
                  body: <CustomSpinner spinnerText={`Clearing cache for player ${userId}. Please wait.`} position={"relative"} />,
                  canClose: false});

      const success = await modalAction();

      showModal({
                  show:            true,
                  title:           `Clear Player Cache`,
                  body:            success ? <div className="white">Player cache cleared successfully.</div> : <div className="white">Failed to clear player cache.</div>,
                  canClose:        true
                });
    }

    showModal({
                show:            true,
                title:           `Clear Player Cache`,
                body:            <div className="white">{modalBody}</div>,
                action:          <Button variant="danger" onClick={() => doAction()}>Clear</Button>,
                canClose:        true
              });
  }

  return {
    clearPlayerCacheModal
  };
};
