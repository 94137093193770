import {StringProperty} from "../../../../model/data";
import React, { FC, useCallback } from 'react';
import {DataComponentPropTypes} from "../data-component-prop-types";
import {PropertyComponent} from "./property-component";
import {StringInput} from "../Inputs/string-input";

export const StringPropertyComponent : FC<DataComponentPropTypes<StringProperty>> = ({route, dataObject, readonly, displayLabel}) => {

  const isValid = useCallback((value: string | undefined) => !(value == null || value.length === 0), []);

  return <PropertyComponent<string | undefined>
        route={route}
        dataObject={dataObject}
        readonly={readonly ?? false}
        displayLabel={displayLabel}
        parseValue={v => v}
        isValid={isValid}
        formComponent={(value, handleOnChange, disabled) => (
            <StringInput value={value} handleOnChange={handleOnChange} disabled={disabled} maxLength={dataObject.maxLength} data-testid={`input${dataObject.id}`}/>
        )}
    />
}
