import Axios, {AxiosResponse} from 'axios';
import {DataObjectBase} from '../../model/data';
import {NotificationType, usePublishNotification} from '@yakoffice/notification-handler';
import {useDataSourceApiUrl} from '../use-data-source-api-url';
import {useParams} from 'react-router-dom';
import {useRequestHeader} from '@yakoffice/yakoffice-firebase';
import {useClearPlayerCacheModal} from './use-clear-player-cache-modal';


export interface IClearPlayerCacheController {
  handleClearPlayerCache(): Promise<void>;
}
export const useClearPlayerCacheController = (): IClearPlayerCacheController => {

  const params = useParams();
  const header = useRequestHeader();
  const publishNotification = usePublishNotification();
  const url = useDataSourceApiUrl();
  const clearPlayerCacheModal = useClearPlayerCacheModal();


  const handleClearPlayerCache = async () => {

    const clearPlayerCache: () => Promise<boolean> = async () => {
      return await Axios
        .post<DataObjectBase>(url + `${params.itemId}/clearPlayerCache`, {}, header)
        .then((res: AxiosResponse<any>) => {

          if(res.data.length > 0) {

            publishNotification({
                                  title:            'No player entries found for: ',
                                  notificationType: NotificationType.Info,
                                  message:          `${res.data.toString().replace(/,/g, "\n")}`
                                });
          }
          return true;
        })
        .catch(e => {
                 if (e.response?.status !== 404)
                   publishNotification({
                                         title:            'Error clearing cache for player: ' + params.itemId,
                                         notificationType: NotificationType.Error,
                                         message:          e.toString()
                                       });
                 return false;
               }
        );
    }

    const modalMessage = `Are you sure you want to delete the player cache for player ${params.itemId}?`;

    if(params.itemId)
      clearPlayerCacheModal.clearPlayerCacheModal(clearPlayerCache, modalMessage, true, params.itemId);
  };


  return {
    handleClearPlayerCache
  };
};
